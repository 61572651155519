const storage = window.localStorage;

const USER_KEY = 'kam_user';

function getUser() {
  return JSON.parse(storage.getItem(USER_KEY));
}

export function login(username) {
  storage.setItem(USER_KEY, JSON.stringify({ logged: true, username }));
}

export function logout() {
  storage.removeItem(USER_KEY);
}

export function isUserLogged() {
  return getUser()?.logged;
}
