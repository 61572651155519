import Vue from 'vue';
import App from './App.vue';
import router from './router';

// Cleave (input masks)
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.br';

Vue.directive('cleave', (el, binding) => {
  const input = el.querySelector('input');
  new Cleave(input, binding.value);
});

// Buefy
import './assets/css/reset.scss';
import './assets/css/styles.scss';
import Buefy from 'buefy';

Vue.use(Buefy);

// Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
