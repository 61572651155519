import { customerStorage, loginStorage } from '@/store';
import { customerService } from '@/services/customer';

export function beforeEnter(to, from, next) {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const isUserLogged = loginStorage.isUserLogged();

  if (authRequired && !isUserLogged) {
    next('/login');
  } else {
    const isToCompleted = to?.name === 'CompletedView';

    if (isToCompleted) {
      next();
    } else {
      customerService
        .getCustomer()
        .then((response) => {
          const finishedRoutes = [
            'APROVADO',
            'EM_ANALISE',
            'EM_REVISAO',
            'RECEBIDO',
          ];
          const customer = response?.data;
          customerStorage.setCustomer(customer);
          if (finishedRoutes.includes(customer?.registerStatus)) {
            next('/completed');
          } else {
            next();
          }
        })
        .catch(() => next());
    }
  }
}
