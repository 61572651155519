<template>
  <Transition name="fade" mode="out-in">
    <main class="full-height full-width half-background">
      <section :class="classes">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-5">
            <div
              class="is-flex is-flex-direction-column is-justify-content-center"
            >
              <b-image
                class="logo"
                :src="require('@/assets/image/kamino_logo_black.svg')"
                alt="Kamino logo"
              />
              <p class="subtitle">
                Seu caminho de founder pode ser melhor, pode ser além, se ele
                for Kamino.
              </p>
            </div>
          </div>
          <div class="tile is-parent is-3"></div>
          <div class="tile is-parent is-4">
            <form class="tile is-child box">
              <b-message
                v-if="hasError"
                type="is-danger"
                aria-close-label="Close message"
              >
                Usuário ou senhas incorretos!
              </b-message>
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <b-field label="Usuário">
                      <b-input
                        v-model="username"
                        aria-placeholder="Digite o usuário"
                        placeholder="Digite o usuário"
                        icon="account"
                      />
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Senha">
                      <b-input
                        v-model="password"
                        aria-placeholder="Digite sua senha"
                        placeholder="Digite sua senha"
                        icon="lock"
                        password-reveal
                        type="password"
                      />
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div :class="buttonClasses">
                    <b-button
                      type="is-link"
                      :disabled="shouldDisableSubmitButton"
                      :loading="loading"
                      @click="onSubmit"
                    >
                      Entrar
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  </Transition>
</template>

<script>
import { authService } from '@/services/auth';

export default {
  name: 'LoginView',
  data() {
    return {
      hasError: false,
      loading: false,
      password: '',
      username: '',
    };
  },
  computed: {
    classes() {
      return 'container is-flex is-align-items-center is-justify-content-center full-height';
    },
    buttonClasses() {
      return 'column is-2 is-offset-10 is-flex is-justify-content-end';
    },
    shouldDisableSubmitButton() {
      return !(this.username && this.password);
    },
  },
  methods: {
    onSubmit() {
      this.loading = true;
      return authService
        .login({
          username: this.username,
          password: this.password,
          rememberMe: false,
        })
        .then(() => {
          this.$router.push('/home');
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
.full-height {
  height: 100%;
}

.full-width {
  position: relative;
  width: 100%;
}

.logo {
  margin-bottom: 16px;
  max-width: 224px;
}

@media (min-width: 769px) {
  .half-background {
    background-image: linear-gradient(to left, transparent 50%, $background 50%),
      url('../../assets/image/founders_notebook.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    display: table;
    height: 100vh;
    width: 100vw;
  }
}
</style>
