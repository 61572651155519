import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeEnter } from './beforeEnter';

import { LoginView } from '@/views/Login';

Vue.use(VueRouter);

const routes = [
  {
    component: LoginView,
    name: 'LoginView',
    path: '/login',
  },
  {
    beforeEnter: beforeEnter,
    component: () => import('../views/Home/HomeView.vue'),
    name: 'HomeView',
    path: '/home',
  },
  {
    beforeEnter: beforeEnter,
    component: () => import('../views/Form/FormView.vue'),
    name: 'FormView',
    path: '/form',
  },
  {
    beforeEnter: beforeEnter,
    component: () => import('../views/Completed/CompletedView.vue'),
    name: 'CompletedView',
    path: '/completed',
  },
  {
    path: '*',
    redirect: '/home',
  },
];

const router = new VueRouter({
  routes,
});

export default router;
