const storage = window.localStorage;

const CUSTOMER_KEY = 'kam_customer';

export function getCustomer() {
  return JSON.parse(storage.getItem(CUSTOMER_KEY));
}

export function setCustomer(customer) {
  storage.setItem(CUSTOMER_KEY, JSON.stringify(customer));
}

export function setCustomerCompanyId(companyId) {
  const customer = { ...getCustomer(), id: companyId };
  setCustomer(customer);
}

export function clearCustomer() {
  storage.removeItem(CUSTOMER_KEY);
}
