import { customerStorage } from '@/store';
import { requestService } from '@/services/request';

const PATH = '/api/customer';
const PATH_CUSTOMERS = '/api/customers';

export function getCustomer() {
  return requestService.get(PATH);
}

export function updateStatus({ companyId, registerStatus }) {
  const company = {
    company: {
      id: companyId,
      registerStatus,
    },
  };

  // console.log(JSON.stringify(company));

  return requestService
    .patch(`${PATH_CUSTOMERS}/update-status`, company)
    .then((response) => {
      if (response.status === 201) {
        const customer = response?.data?.company;
        customerStorage.setCustomer(customer);
        return true;
      }
    })
    .catch(() => false);
}
