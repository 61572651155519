import axios from 'axios';

import { customerStorage, loginStorage } from '@/store';
import { requestService } from '@/services/request';

const PATH = '/api/authenticate';
const AUTHORIZATION_KEY = 'Authorization';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

function setAuthorizationToken(authToken) {
  axios.defaults.headers.common[AUTHORIZATION_KEY] = `Bearer ${authToken}`;
}

export function getAuthorizationToken() {
  return axios.defaults.headers.common[AUTHORIZATION_KEY];
}

export function login({ username, password, rememberMe = false }) {
  return requestService
    .post(PATH, { username, password, rememberMe })
    .then((response) => {
      const authorizationToken = response?.data?.id_token;
      setAuthorizationToken(authorizationToken);
      loginStorage.login(username);
      return true;
    });
}

export function logout() {
  delete axios.defaults.headers.common[AUTHORIZATION_KEY];
  loginStorage.logout();
  customerStorage.clearCustomer();
}
