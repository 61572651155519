import axios from 'axios';

import { authService } from '@/services/auth';

function getHeaders() {
  return {
    Authorization: authService.getAuthorizationToken(),
    'Content-Type': 'application/json',
  };
}

export function get(path, config) {
  return axios.get(path, {
    headers: getHeaders(),
    ...config,
  });
}

export function post(path, data, config) {
  return axios.post(path, data, {
    headers: getHeaders(),
    ...config,
  });
}

export function put(path, data, config) {
  return axios.put(path, data, {
    headers: getHeaders(),
    ...config,
  });
}

export function patch(path, data, config) {
  return axios.patch(path, data, {
    headers: getHeaders(),
    ...config,
  });
}

export function remove(path, config) {
  return axios.delete(path, {
    headers: getHeaders(),
    ...config,
  });
}
